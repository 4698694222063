@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500&display=swap");

* {
  padding: "0";
  margin: "0";
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Helvetica" !important;
  /*overflow-y: hidden;*/
}

.fonts {
  font-family: Helvetica !important;
}

.circle {
  padding: 5px;
  border-radius: 20px;
  background-color: "rgba(252, 224, 239, 1)";
}


.cont-sl{
	margin-bottom: 20px;
  margin-top: -5px;
}


.fom-lt{
	/*margin-bottom: 60px;*/
  margin-top: 20px;
}


.fom-rt{
	height: 70% !important;
	margin-top: 85px;
	margin-right: 80px;
}


.abt-bg{
	background-color: #F2F2F2;
}


.arow-l{
	margin-left: 10px;
}


.sub-btn{
	margin-left: 180px;
  margin-top: 20px;
}


.imag-lt{
	margin-top: 85px;
}


.cons-trans{
		background-image: url(assets/constransparent.svg);
		background-repeat: no-repeat;
		padding-top: 100px;
}


.cons-img{
	background-image: url(assets/costrans.svg);
		background-repeat: no-repeat;
}


.fund-trans{
		background-image: url(assets/fund-trans.svg);
		background-repeat: no-repeat;
}


.localtrans{
	background-image: url(assets/localtrans.svg);
	background-repeat: no-repeat;
	background-position: left-bottom;
}

.row {
  /* display: flex; */
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.risktrans {
  background-image: url(assets/risktrans.svg);
  background-repeat: no-repeat;
}


.abouttrans{
background-image: url(assets/abouttrans.svg);
background-repeat: no-repeat;
padding-bottom: 150px;
}


/*ABOUT US PART*/
.visitxt {
  margin-right: 100px;
}

/*Small devices (280px)*/
@media only screen
and (min-device-width: 280px) 
and (max-device-width: 653px) {
.abouttrans{
		padding-top: 150px;
	}
	.map-in{
		display: none;
	}

	.fom-lt{
		margin-left: 30px;
	}


	.fom-rt {
    margin-right: 2px;
    margin-left: -25px;
	}

	.sub-btn {
    margin-left: 100px;
	}

	.servic-part{
 margin-top: 150px;
	}

	.servi-part{
 margin-top: 50px;
	}

	.dribid{
		padding-bottom:50px;
	}

	.mgtteam{
margin-left: 80px;
	}

	.mtgbx{
		margin-right: 40px;
    margin-top: 20px;
    margin-bottom: 100px;
	}

}



/*Small devices (320px)*/
@media only screen
and (min-device-width: 320px) 
and (max-device-width: 538px) {
.abouttrans{
		padding-top: 150px;
	}

	.map-in{
		display: none;
	}

	.fom-lt{
		margin-left: 30px;
	}


	.fom-rt {
    margin-right: 5px;
    margin-left: 5px;
	}

	.sub-btn {
    margin-left: 100px;
	}

	.abt{
		padding-top: 100px;
		padding-bottom: 100px;
	}
.visiont{
	margin-left: 0px !important;
}

	.servic-part{
 margin-top: 150px;
	}

	.servi-part{
 margin-top: 50px;
	}

	.dribid{
		padding-bottom:50px;
	}

	.mgtteam{
margin-left: 80px;
	}

	.mtgbx{
		margin-right: 40px;
    margin-top: 20px;
    margin-bottom: 100px;
	}

}

/*Small devices (375px)*/
@media only screen
and (min-device-width: 375px) 
and (max-device-width: 538px) {
.abouttrans{
		padding-top: 150px;
	}

	.map-in{
		display: none;
	}

	.fom-lt{
		margin-left: 30px;
	}


	.abt{
		padding-top: 100px;
		padding-bottom: 100px;
	}
.visiont{
	margin-left: 0px !important;
}
	.fom-rt {
    margin-right: 30px;
    margin-left: 25px;
	}

	.sub-btn {
    margin-left: 100px;
	}

	.servic-part{
 margin-top: 150px;
	}

	.servi-part{
 margin-top: 50px;
	}

	.dribid{
		padding-bottom:50px;
	}

	.mgtteam{
margin-left: 80px;
	}

	.mtgbx{
		margin-right: 40px;
    margin-top: 20px;
    margin-bottom: 100px;
	}

}


/*Small devices (425px)*/
@media only screen
and (min-device-width: 425px) 
and (max-device-width: 538px) {
	.abouttrans{
		padding-top: 120px;
	}

	.map-in{
		display: none;
	}

	.fom-lt{
		margin-left: 30px;
	}

	.abt{
		padding-top: 100px;
		padding-bottom: 100px;
	}

	.fom-rt {
    margin-right: 30px;
    margin-left: 15px;
	}

	.sub-btn {
    margin-left: 100px;
	}

	.servic-part{
 margin-top: 150px;
	}

	.servi-part{
 margin-top: 50px;
	}

	.dribid{
		padding-bottom:50px;
	}

	.mgtteam{
margin-left: 80px;
	}

	.mtgbx{
		margin-right: 40px;
    margin-top: 20px;
    margin-bottom: 100px;
	}


}

/*Small devices (540px)*/
@media only screen
and (min-device-width: 540px) 
and (max-device-width: 720px) {
	.abouttrans{
		padding-top: 120px;
	}

	.map-in{
		display: none;
	}

	.fom-lt{
		margin-left: 30px;
	}


	.abt{
		padding-top: 100px;
		padding-bottom: 100px;
	}
.visiont{
	margin-left: 0px !important;
}


	.fom-rt {
    margin-right: 30px;
    margin-left: 15px;
	}

	.sub-btn {
    margin-left: 100px;
	}

	.servic-part{
 margin-top: 150px;
	}

	.servi-part{
 margin-top: 50px;
	}

	.dribid{
		padding-bottom:50px;
	}

	.mgtteam{
margin-left: 80px;
	}

	.mtgbx{
		margin-right: 40px;
    margin-top: 20px;
    margin-bottom: 100px;
	}


} 


@media (min-width: 768px) {
	.abouttrans {
padding-top: 100px;
	}

/*.fom-lt{
margin-right: 80px;
}*/

	.fom-rt{
		margin-left: 80px;
		margin-right: 80px;
	}
}

  .board {
    padding-bottom: 80px;
  }

@media (min-width: 1024px) {
.md-pic{
	padding-bottom: 60px;
}

.fom-rt {
    height: 100% !important;
    margin-top: 85px;
	}

.board{
	padding-bottom:80px;
}


.fom-lt{
	margin-left: 20px;
  margin-right: 20px;
}

.pple-part{
	margin-left: 350px;
}


.loan-text{
	margin-left: 35px;
}

}

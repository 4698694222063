.content {
  position: relative;
}
.front {
  transition: 0.5s;
  z-index: 1;
  position: relative;
  /* width: 200px;
  height: 600px; */
}
.back {
  position: absolute;
  top: 50px;
  left: 0;
  opacity: 0;
}
.content:hover .front {
  transform: rotateY(180deg);
  opacity: 0;
}
.content:hover .back {
  opacity: 1;
}
